<template>

  <session-layout>

    <template slot="session-title">
      <h3>Set New Password</h3>
    </template>

    <template slot="session-body">

          <v-text-field
                label="New Password"
                
                v-model="new_password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                name="input-10-1"
                hint="At least 8 characters"
                counter
                @click:append="showPassword = !showPassword"
                validate-on-blur
                persistent-hint
                v-on:keyup.enter="submit">
        </v-text-field>
         <v-text-field
                label="Confirm Password"
                v-model="confirm_password"
                :append-icon="showconPassword ? 'visibility' : 'visibility_off'"
                :type="showconPassword ? 'text' : 'password'"
                :rules="confirmPasswordRules"
                name="input-10-1"
                
                @click:append="showconPassword = !showconPassword"
                validate-on-blur
                persistent-hint
                v-on:keyup.enter="submit">
        </v-text-field>
        <a class="d-block text-right remember" @click="$router.push('/session/login')">Go to login page</a>
          <v-btn
                  depressed
                  :disabled="ForgotDisabled"
                  class="mt-10"
                  style="width: 100%"
                  @click="submit">
            Set New Password
            <v-progress-circular
                    v-if="ForgotInProgress"
                    style="position: absolute; right: 30%;"
                    indeterminate
                    :size="22"
                    color="#fff"
            ></v-progress-circular>
          </v-btn>

    </template>

  </session-layout>

</template>

<script>
  import SessionLayout from './Layout'
  import { mapState } from 'vuex'
  import Vue from 'vue'
  export default {
    name: 'SetNewPassword',
    computed: {
      ForgotDisabled: function() {
        return this.ForgotInProgress || this.new_password == '' || this.confirm_password == ''
      }
    },
    data() {
      return {
        valid: false,
        showPassword: false,
        showconPassword:false,
        ForgotInProgress: false,
        user_id:this.$route.params.userid,
        forgot_code:this.$route.params.forgotkey,
        new_password:'',
        rules: {
          required: value => !!value || 'Password is required.',
          min: v => v.length >= 8 || 'Min 8 characters',
        },
      confirmPasswordRules: [
        (value) => !!value || 'confirm password required',
        (value) =>
          value === this.new_password || 'The password confirmation does not match.',
      ],
        confirm_password:''
      }
    },
    methods:{
    async  submit(){
        this.ForgotInProgress = true;
        if(this.new_password == this.confirm_password)
        {
          if(this.forgot_code != '' && this.user_id != '')
          {
             const payload = {
                'new_password':this.new_password,
                'confirm_password':this.confirm_password,
                'forgot_key':this.forgot_code,
                'user_id':this.user_id
              }
              await this.$store.dispatch('setNewPassword',payload) 
              
          }else{
              Vue.notify({
                group: 'messages',
                type: 'error',
                text:  'Prameters Not Given!'
            })
          }
        }else{
            this.ForgotInProgress = false;
            Vue.notify({
                group: 'messages',
                type: 'error',
                text:  'New password and confirm password is not same!'
            })
        }
       
      }
    },
    components: {
      SessionLayout
    }
  }
</script>